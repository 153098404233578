///*------------------------------------*\
//    #LAYOUT
//\*------------------------------------*/

// Html base styles
html {
    height: 100%;
    overflow: hidden;
}





// Body base styles
body {
    font-family: 'roboto', sans-serif;
    height: 100%;
}




.background {
    @include position(fixed, null 0 null 0);

    z-index: 1;

    display: block;

    background-image: url("../../images/bg.jpg");
    background-repeat: no-repeat;
    -webkit-background-size: cover;
       -moz-background-size: cover;
         -o-background-size: cover;
            background-size: cover;

    @include transition(all 2s ease-in-out);

    @include size(100%);

    &.background--is-blurred {
        opacity: 0
    }
}

.background-blurred {
    @include position(fixed, null 0 null 0);

    opacity: 0;
    z-index: 1;

    display: block;

    background-image: url("../../images/bg-blurred.jpg");
    background-repeat: no-repeat;
    -webkit-background-size: cover;
       -moz-background-size: cover;
         -o-background-size: cover;
            background-size: cover;

    @include transition(all 1s ease-in-out);

    @include size(100%);

    &.background--is-blurred {
        opacity: 1
    }
}