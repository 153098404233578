.event__link {
    color: $black;
    text-decoration: none;
}

.event__cover {
    -webkit-background-size: cover !important;
            background-size: cover !important;
    min-width: 300px;
    width: 100%;
    height: 400px;
}

.event__image {
    margin: auto;
    max-height: 150px;
}