.news-cover__file-input, .news-attachments__file-input {
    display: none;
}

.news__cover-thumbnail {
    max-height: 300px;

    margin: auto;
    margin-top: double($base-spacing-unit);
}

.news__cover-remove {
    @include position(absolute, double($base-spacing-unit) null null null);
}