///*------------------------------------*\
//    #LIGHTBOX
//\*------------------------------------*/

// Lightbox
.lightbox {
    @include position(fixed, 0 0 0 0);
    z-index: 999;
    background-color: rgba(0,0,0,0.8);
    opacity: 0;
    pointer-events: none;
    @include transition-property(opacity);
    @include transition-duration(0.6s);
    @include transition-timing-function($ease-out-quint);
}

.lightbox--is-shown {
    opacity: 1;
    pointer-events: auto;
}

    // Lightbox: wrapper
    .lightbox__wrapper {
        @include position(absolute, 0 0 0 0);
        @include display(flex);
        @include flex-direction(row);
        @include transition-property(transform);
        @include transition-duration(0.6s);
        @include transition-timing-function($ease-out-quint);
    }

    // Lightbox: nav
    .lightbox__nav {
        @include position(absolute, 50% null null null);
        z-index: 1;
        display: block;
        @include size(40px);
        @include transform(translateY(-50%));
        cursor: pointer;
        @include font-size(30px);
        color: $white-2;
        line-height: 40px;
        text-align: center;

        &:hover {
            color: $white-1;
        }
    }

    .lightbox__nav--prev {
        left: $base-spacing-unit * 3;

        .lightbox--first-image & {
            display: none;
        }
    }

    .lightbox__nav--next {
        right: $base-spacing-unit * 3;

        .lightbox--last-image & {
            display: none;
        }
    }





// Lightbox image
.lightbox-image {
    position: relative;
    @include flex(0 0 100%);
    max-width: 100%;
}

    // Lightbox image: wrapper
    .lightbox-image__wrapper {
        @include position(absolute, 10% 10% 10% 10%);

        img {
            @include position(absolute, 50% null null 50%);
            transform: translateX(-50%) translateY(-50%);

            max-width: 100%;
            max-height: 100%;

            margin: 0 auto;
        }

        span {
            @include position(absolute, null null -10 * $base-spacing-unit 50%);
            transform: translateX(-50%);
        }
    }
