.song-cover__file-input, .song-extract__file-input, .song-soundtracks__file-input {
    display: none;
}

.song__cover-thumbnail {
    max-height: 300px;

    margin: auto;
    margin-top: double($base-spacing-unit);
}

.song__cover-remove {
    @include position(absolute, double($base-spacing-unit) null null null);
}

.song__add-year-btn, .song__remove-extract-btn {
    position: absolute !important;
}

.song__remove-extract-btn {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
