// Accent
.icon--circled.icon--accent {
    color: $white;
    background-color: $accent;
}

.icon--flat.icon--accent {
    color: $accent;
}

.btn--fab.btn--accent {
    color: $white;
    background-color: $accent;
}

.btn--icon.btn--accent {
    color: $accent;
}

.btn--raised.btn--accent {
    color: $white;
    background-color: $accent;
}

.btn--flat.btn--accent {
    color: $accent;

    &:hover {
        background-color: rgba(189, 2, 1, 0.15);
    }
}

.bgc-accent {
    background-color: $accent !important;
}

.tc-accent {
    color: $accent !important;
}

.progress-container--circular.progress-container--accent {
    .progress-circular__left .progress-circular__half-circle {
        border-top-color: $accent;
        border-left-color: $accent;
    }

    .progress-circular__right .progress-circular__half-circle {
        border-top-color: $accent;
        border-right-color: $accent;
    }
}

.progress-container--linear.progress-container--accent {
    .progress-linear__background,
    .progress-linear__bar {
        background-color: $accent;
    }
}


// Chroma blue
.icon--circled.icon--chroma-blue {
    color: $white;
    background-color: $chroma-blue-2;
}

.icon--flat.icon--chroma-blue {
    color: $chroma-blue-2;
}

.btn--fab.btn--chroma-blue {
    color: $white;
    background-color: $chroma-blue-2;
}

.btn--icon.btn--chroma-blue {
    color: $chroma-blue-2;
}

.btn--raised.btn--chroma-blue {
    color: $white;
    background-color: $chroma-blue-2;
}

.btn--flat.btn--chroma-blue {
    color: $chroma-blue-2;

    &:hover {
        background-color: rgba(189, 2, 1, 0.15);
    }
}

.bgc-chroma-blue {
    background-color: $chroma-blue-2 !important;
}

.tc-chroma-blue {
    color: $chroma-blue-2 !important;
}

.progress-container--circular.progress-container--chroma-blue {
    .progress-circular__left .progress-circular__half-circle {
        border-top-color: $chroma-blue-2;
        border-left-color: $chroma-blue-2;
    }

    .progress-circular__right .progress-circular__half-circle {
        border-top-color: $chroma-blue-2;
        border-right-color: $chroma-blue-2;
    }
}

.progress-container--linear.progress-container--chroma-blue {
    .progress-linear__background,
    .progress-linear__bar {
        background-color: $chroma-blue-2;
    }
}


// Chroma orange
.icon--circled.icon--chroma-orange {
    color: $white;
    background-color: $chroma-orange;
}

.icon--flat.icon--chroma-orange {
    color: $chroma-orange;
}

.btn--fab.btn--chroma-orange {
    color: $white;
    background-color: $chroma-orange;
}

.btn--icon.btn--chroma-orange {
    color: $chroma-orange;
}

.btn--raised.btn--chroma-orange {
    color: $white;
    background-color: $chroma-orange;
}

.btn--flat.btn--chroma-orange {
    color: $chroma-orange;

    &:hover {
        background-color: rgba(189, 2, 1, 0.15);
    }
}

.bgc-chroma-orange {
    background-color: $chroma-orange !important;
}

.bgc-chroma-orange-2 {
    background-color: rgba(255, 173, 63, 0.6) !important;
}

.tc-chroma-orange {
    color: $chroma-orange !important;
}

.progress-container--circular.progress-container--chroma-orange {
    .progress-circular__left .progress-circular__half-circle {
        border-top-color: $chroma-orange;
        border-left-color: $chroma-orange;
    }

    .progress-circular__right .progress-circular__half-circle {
        border-top-color: $chroma-orange;
        border-right-color: $chroma-orange;
    }
}

.progress-container--linear.progress-container--chroma-orange {
    .progress-linear__background,
    .progress-linear__bar {
        background-color: $chroma-orange;
    }
}