// More data-table possibilities
.data-table {
    // Action cell
    .data-table__action-cell {
        width: quadruple($base-spacing-unit * 1.5);
    }

    // Data table clickable row
    .data-table__clickable-row {
        td {
            cursor: pointer;
        }

        &:not(.data-table__clickable-row--is-disabled):hover {
            td {
                background-color: $grey-200;
            }
        }
    }

    .data-table__row--is-new {
        td {
            background-color: $light-blue-50;
        }
    }
    .data-table__row--is-live {
    }
    .data-table__row--is-hidden {
        td {
            background-color: $blue-grey-50;
        }
    }
    .data-table__row--is-disabled {
        td {
            background-color: $amber-50;
        }
    }
    .data-table__row--is-deleted {
        td {
            background-color: $deep-orange-50;
        }
    }

    // Link in a data table
    .data-table__link {
        color: $black-1;

        &:hover {
            font-weight: bold;
        }
    }

    // Thumbnail in a column
    .data-table__row--has-thumbnail {
        padding: double($base-spacing-unit) 0 !important;

        .data-table__row-thumbnail {
            margin: auto;

            &.data-table__row-thumbnail--round {
                border-radius: 50%;
            }
        }
    }

    // Make in edition row not selectable
    .data-table__selectable-row {
        &.data-table__row--in-edition {
            td:first-child:before {
                content: none;
            }
        }
    }

    th, td {
        padding-right: double($base-spacing-unit);

        overflow: hidden;
        text-overflow: ellipsis;
    }

    .lx-select {
        text-align: left;

        margin-top: $base-spacing-unit;
    }
}