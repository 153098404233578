///*------------------------------------*\
//    #LUMX
//\*------------------------------------*/

@import "common/scss/settings";

@import "../bower_components/lumx/dist/scss/lumx";

@import "common/scss/mixins";

@import "common/scss/layout";
@import "common/scss/header";
@import "common/scss/main";
@import "common/scss/footer";
@import "common/scss/defaults";
@import "common/scss/helpers";
@import "common/scss/overrides";
@import "common/scss/data-table";
@import "common/scss/animations";
@import "common/scss/colors";
@import "common/scss/lightbox";
@import "common/scss/slideshow";

@import "common/scss/menu/menu";

@import "common/scss/news/news";
@import "common/scss/events/events";
@import "common/scss/medias/medias";

@import "admin/board/scss/board";
@import "admin/news/scss/news";
@import "admin/repertoire/scss/song";
@import "admin/events/scss/events";

@import "members/soundtracks/scss/soundtracks";

@import "public/choir/scss/president";
@import "public/choir/scss/board";
@import "public/repertoire/scss/repertoire";
@import "public/contact/scss/contact";

.fade.ng-enter, .fade.ng-leave {
    @include transition(opacity 0.8s ease);
}

.fade.ng-enter {
    opacity: 0;
}

.fade.ng-enter-active {
    opacity: 1;
}

.fade.ng-leave {
    opacity: 1;

}

.fade.ng-leave-active {
    display: none;
    opacity: 0;
}

// Accent
.icon--circled.icon--accent {
    color: $white;
    background-color: $accent;
}

.icon--flat.icon--accent {
    color: $accent;
}

.btn--fab.btn--accent {
    color: $white;
    background-color: $accent;
}

.btn--icon.btn--accent {
    color: $accent;
}

.btn--raised.btn--accent {
    color: $white;
    background-color: $accent;
}

.btn--flat.btn--accent {
    color: $accent;

    &:hover {
        background-color: rgba(189, 2, 1, 0.15);
    }
}

.bgc-accent {
    background-color: $accent !important;
}

.tc-accent {
    color: $accent !important;
}

.progress-container--circular.progress-container--accent {
    .progress-circular__left .progress-circular__half-circle {
        border-top-color: $accent;
        border-left-color: $accent;
    }

    .progress-circular__right .progress-circular__half-circle {
        border-top-color: $accent;
        border-right-color: $accent;
    }
}

.progress-container--linear.progress-container--accent {
    .progress-linear__background,
    .progress-linear__bar {
        background-color: $accent;
    }
}


// Chroma blue
.icon--circled.icon--chroma-blue {
    color: $white;
    background-color: $chroma-blue-2;
}

.icon--flat.icon--chroma-blue {
    color: $chroma-blue-2;
}

.btn--fab.btn--chroma-blue {
    color: $white;
    background-color: $chroma-blue-2;
}

.btn--icon.btn--chroma-blue {
    color: $chroma-blue-2;
}

.btn--raised.btn--chroma-blue {
    color: $white;
    background-color: $chroma-blue-2;
}

.btn--flat.btn--chroma-blue {
    color: $chroma-blue-2;

    &:hover {
        background-color: rgba(189, 2, 1, 0.15);
    }
}

.bgc-chroma-blue {
    background-color: $chroma-blue-2 !important;
}

.tc-chroma-blue {
    color: $chroma-blue-2 !important;
}

.progress-container--circular.progress-container--chroma-blue {
    .progress-circular__left .progress-circular__half-circle {
        border-top-color: $chroma-blue-2;
        border-left-color: $chroma-blue-2;
    }

    .progress-circular__right .progress-circular__half-circle {
        border-top-color: $chroma-blue-2;
        border-right-color: $chroma-blue-2;
    }
}

.progress-container--linear.progress-container--chroma-blue {
    .progress-linear__background,
    .progress-linear__bar {
        background-color: $chroma-blue-2;
    }
}


// Chroma orange
.icon--circled.icon--chroma-orange {
    color: $white;
    background-color: $chroma-orange;
}

.icon--flat.icon--chroma-orange {
    color: $chroma-orange;
}

.btn--fab.btn--chroma-orange {
    color: $white;
    background-color: $chroma-orange;
}

.btn--icon.btn--chroma-orange {
    color: $chroma-orange;
}

.btn--raised.btn--chroma-orange {
    color: $white;
    background-color: $chroma-orange;
}

.btn--flat.btn--chroma-orange {
    color: $chroma-orange;

    &:hover {
        background-color: rgba(189, 2, 1, 0.15);
    }
}

.bgc-chroma-orange {
    background-color: $chroma-orange !important;
}

.bgc-chroma-orange-2 {
    background-color: rgba(255, 173, 63, 0.6) !important;
}

.tc-chroma-orange {
    color: $chroma-orange !important;
}

.progress-container--circular.progress-container--chroma-orange {
    .progress-circular__left .progress-circular__half-circle {
        border-top-color: $chroma-orange;
        border-left-color: $chroma-orange;
    }

    .progress-circular__right .progress-circular__half-circle {
        border-top-color: $chroma-orange;
        border-right-color: $chroma-orange;
    }
}

.progress-container--linear.progress-container--chroma-orange {
    .progress-linear__background,
    .progress-linear__bar {
        background-color: $chroma-orange;
    }
}
// More data-table possibilities
.data-table {
    // Action cell
    .data-table__action-cell {
        width: quadruple($base-spacing-unit * 1.5);
    }

    // Data table clickable row
    .data-table__clickable-row {
        td {
            cursor: pointer;
        }

        &:not(.data-table__clickable-row--is-disabled):hover {
            td {
                background-color: $grey-200;
            }
        }
    }

    .data-table__row--is-new {
        td {
            background-color: $light-blue-50;
        }
    }
    .data-table__row--is-live {
    }
    .data-table__row--is-hidden {
        td {
            background-color: $blue-grey-50;
        }
    }
    .data-table__row--is-disabled {
        td {
            background-color: $amber-50;
        }
    }
    .data-table__row--is-deleted {
        td {
            background-color: $deep-orange-50;
        }
    }

    // Link in a data table
    .data-table__link {
        color: $black-1;

        &:hover {
            font-weight: bold;
        }
    }

    // Thumbnail in a column
    .data-table__row--has-thumbnail {
        padding: double($base-spacing-unit) 0 !important;

        .data-table__row-thumbnail {
            margin: auto;

            &.data-table__row-thumbnail--round {
                border-radius: 50%;
            }
        }
    }

    // Make in edition row not selectable
    .data-table__selectable-row {
        &.data-table__row--in-edition {
            td:first-child:before {
                content: none;
            }
        }
    }

    th, td {
        padding-right: double($base-spacing-unit);

        overflow: hidden;
        text-overflow: ellipsis;
    }

    .lx-select {
        text-align: left;

        margin-top: $base-spacing-unit;
    }
}
// Defaults
h1, h2, h3, h4, h5 {
    text-align: center;
    color: $accent;
}

p {
    text-indent: quadruple($base-spacing-unit);
    text-align: justify;
}

u {
    text-decoration: underline;
}

[flex-item-align="stretch"] {
    @include media-query(desk) {
        @include align-self(stretch);
    }
}

input[type="time"] {
    &::-webkit-inner-spin-button, &::-webkit-clear-button, &::-webkit-datetime-edit-millisecond-field, &::-webkit-datetime-edit-second-field {
        display: none;
    }
}

blockquote {
    margin-left: double($base-spacing-unit);
    padding-left: $base-spacing-unit;
    border-left: 1px solid $black-3
}

///*------------------------------------*\
//    #FOOTER
//\*------------------------------------*/

// Header base styles
.footer {
    padding-bottom: double($base-spacing-unit);
    @include font-size(14px);
    line-height: 32px;
    text-align: center;
}

    // Footer Link
    .footer__link img {
        display: inline-block;
        vertical-align: top;
        height: 32px;
        margin-left: halve($base-spacing-unit);
    }
///*------------------------------------*\
//    #HEADER
//\*------------------------------------*/

// Header base styles
.header {
    display: none;

    @include position(fixed, 0 0 null 0);
    z-index: 999;
    height: 60px;
    background-color: $chroma-orange;
    @include z-depth(1);
    @include clearfix;

    &.header--is-visible {
        display: block;
    }
}





// Menubar
.menubar {
    @include media-query(desk) {
        display: none;
    }

    @include media-query(portable) {
        float: left;
    }
}

.home .menubar {
    display: none;
}





// Main Logo
.main-logo {
    float: left;

    margin: $base-spacing-unit;
    margin-left: double($base-spacing-unit);

    @include transform(rotate(12deg));

    img {
        border: 2px solid $white;
    }
}

    // Main Logo Link
    .main-logo__link {
        display: block;
        padding: halve($base-spacing-unit) $base-spacing-unit;
        border-radius: $base-round;
        @include font-size(24px);

        img {
            display: block;
            height: 100px;

            @include transition(height .5s, margin-top .5s);

            &.main-logo--small {
                margin-top: $base-spacing-unit * -1;
                height: 50px;
            }
        }
    }





// Nav bar
.nav-bar {
    height: 100%;

    margin-top: $base-spacing-unit;

    ul {
        @extend %bare-list;

        li {
            float: left;
        }
    }
}

.nav-bar--lap-and-up {
    @include media-query(palm) {
        display: none;
    }
}

.nav-bar--palm {
    @include media-query(lap-and-up) {
        display: none;
    }
}

.nav-bar__dropdown {
    border-radius: 0;
    border-top: 5px solid $chroma-blue-1;

    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
}

    // Nav Link
    .nav-bar__link {
        display: block;
        padding: 0 $base-spacing-unit;
        border-radius: double($base-round) double($base-round) 0 0;
        @include font-size(18px);
        font-weight: 600;
        color: $white;
        line-height: double($base-line-height);
        text-decoration: none;

        margin-top: halve($base-spacing-unit);

        &:hover {
            background-color: $orange-500;
        }

        @include media-query(lap) {
            @include font-size(14px);
        }
    }

.dropdown.dropdown--is-active .nav-bar__link {
    background-color: $chroma-blue-1;
}





// Main Nav
.nav-bar--main {
    @include position(absolute, null null null 160px);
}





// Login menu
.nav-bar--login {
    @include position(absolute, null double($base-spacing-unit) null null);
    margin-top: $base-spacing-unit + quarter($base-spacing-unit);
}

.nav-bar--login__dropdown {
    margin-top: $base-spacing-unit + quarter($base-spacing-unit);
}

    .nav-bar--login__btn-login {
        text-align: center;
        @include margin($base-spacing-unit $base-spacing-unit);
    }





// Legged in menu
.nav-bar--logged-in {
    @include position(absolute, null double($base-spacing-unit) null null);
    margin-top: $base-spacing-unit + halve($base-spacing-unit);
}

// Helpers
// Make an element appears clickable
.clickable {
    cursor: pointer !important;
}

// Add a border to an image
.img--with-border {
    border: 1px solid rgba(0, 0, 0, 0.5);
}

// Display inline block.
.display-inline-block {
    display: inline-block !important;
}

.text-decoration-none {
    text-decoration: none !important;
}

///*------------------------------------*\
//    #LAYOUT
//\*------------------------------------*/

// Html base styles
html {
    height: 100%;
    overflow: hidden;
}





// Body base styles
body {
    font-family: 'roboto', sans-serif;
    height: 100%;
}




.background {
    @include position(fixed, null 0 null 0);

    z-index: 1;

    display: block;

    background-image: url("../../images/bg.jpg");
    background-repeat: no-repeat;
    -webkit-background-size: cover;
       -moz-background-size: cover;
         -o-background-size: cover;
            background-size: cover;

    @include transition(all 2s ease-in-out);

    @include size(100%);

    &.background--is-blurred {
        opacity: 0
    }
}

.background-blurred {
    @include position(fixed, null 0 null 0);

    opacity: 0;
    z-index: 1;

    display: block;

    background-image: url("../../images/bg-blurred.jpg");
    background-repeat: no-repeat;
    -webkit-background-size: cover;
       -moz-background-size: cover;
         -o-background-size: cover;
            background-size: cover;

    @include transition(all 1s ease-in-out);

    @include size(100%);

    &.background--is-blurred {
        opacity: 1
    }
}
///*------------------------------------*\
//    #LIGHTBOX
//\*------------------------------------*/

// Lightbox
.lightbox {
    @include position(fixed, 0 0 0 0);
    z-index: 999;
    background-color: rgba(0,0,0,0.8);
    opacity: 0;
    pointer-events: none;
    @include transition-property(opacity);
    @include transition-duration(0.6s);
    @include transition-timing-function($ease-out-quint);
}

.lightbox--is-shown {
    opacity: 1;
    pointer-events: auto;
}

    // Lightbox: wrapper
    .lightbox__wrapper {
        @include position(absolute, 0 0 0 0);
        @include display(flex);
        @include flex-direction(row);
        @include transition-property(transform);
        @include transition-duration(0.6s);
        @include transition-timing-function($ease-out-quint);
    }

    // Lightbox: nav
    .lightbox__nav {
        @include position(absolute, 50% null null null);
        z-index: 1;
        display: block;
        @include size(40px);
        @include transform(translateY(-50%));
        cursor: pointer;
        @include font-size(30px);
        color: $white-2;
        line-height: 40px;
        text-align: center;

        &:hover {
            color: $white-1;
        }
    }

    .lightbox__nav--prev {
        left: $base-spacing-unit * 3;

        .lightbox--first-image & {
            display: none;
        }
    }

    .lightbox__nav--next {
        right: $base-spacing-unit * 3;

        .lightbox--last-image & {
            display: none;
        }
    }





// Lightbox image
.lightbox-image {
    position: relative;
    @include flex(0 0 100%);
    max-width: 100%;
}

    // Lightbox image: wrapper
    .lightbox-image__wrapper {
        @include position(absolute, 10% 10% 10% 10%);

        img {
            @include position(absolute, 50% null null 50%);
            transform: translateX(-50%) translateY(-50%);

            max-width: 100%;
            max-height: 100%;

            margin: 0 auto;
        }

        span {
            @include position(absolute, null null -10 * $base-spacing-unit 50%);
            transform: translateX(-50%);
        }
    }

///*------------------------------------*\
//    #MAIN
//\*------------------------------------*/

// Main base styles
.main {
    height: 100%;
}

.main-content {
    height: 100%;
}





#main-scrollbar {
    opacity: 0;
    @include transition(opacity 0.6s ease-in-out);

    min-height: 100%;

    overflow-y: hidden;

    background-color: $white-2;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.8);

    margin: quadruple($base-spacing-unit);
    margin-right: 8 * $base-spacing-unit;
    margin-left: 270px;

    padding: quadruple($base-spacing-unit);
    padding-top: double($base-spacing-unit);

    @include position(fixed, null 0 null 0);
    z-index: 2;

    > div[ng-transclude] {
        height: 100%;
        margin-bottom: $base-spacing-unit;
    }

    &.main-scrollbar--is-visible {
        opacity: 1;
    }

    &.main-scrollbar--with-header {
        margin-top: 60px;
    }
}

// Scrollbar
.ps-container > .ps-scrollbar-y-rail {
    width: 10px !important;
    z-index: 20;

    .ps-scrollbar-y {
        width: 10px !important;

        background-color: $chroma-blue-2 !important;
    }
}

.scrollbar-y-axis {
    z-index: 999;
}





// Main section
.main-section {
    @include media-query(desk) {
        margin: 0 0 0 260px;
    }
}

    // Main section title
    .main-section__title {
        display: block;
        height: quadruple($base-spacing-unit * 2);
        margin: 0;
        padding: 0 double($base-spacing-unit);
        border-bottom: 1px solid #ddd;
        @include font-size(22px);
        font-weight: 600;
        line-height: quadruple($base-spacing-unit * 2);

        background-color: #ff3333;

        padding-left: 20px;
    }

    // Main section intro
    .main-section__intro {
        margin: 0;
        padding: double($base-spacing-unit);
        @include font-size(22px);
        font-weight: 300;

        a {
            color: $blue;
            text-decoration: none;
        }
    }

    // Main section content
    .main-section__content {
        padding: double($base-spacing-unit);

        p > code {
            padding: 2px 4px;
            font-size: 90%;
            color: $blue;
            background-color: lighten($blue, 40%);
            border-radius: 4px;
        }

        p > a {
            color: $blue;
            text-decoration: none;
        }

        p:last-child,
        ul:last-child {
            margin-bottom: 0;
        }
    }

    // Main section subtitle
    .main-section__subtitle {
        @include font-size(30px);
        font-weight: 300;
        line-height: 1;
    }





// Wrapper
.wrapper {
    .wrapper--white {
        border-radius: 5px;
        background-color: $white-1;

        padding: double($base-spacing-unit);
    }

    .wrapper--small {
        padding: quadruple($base-spacing-unit);

        width: 100%
    }
}





// Really floatable FAB, bottom right positionned
.real-fab {
    @include position(absolute, halve($base-spacing-unit) (quadruple($base-spacing-unit) + halve($base-spacing-unit)) null null);
}





// Various
.btn .progress-container {
    margin-top: halve($base-spacing-unit) * -1;
}

@mixin blur($radius) {
  -webkit-filter: blur($radius);
     -moz-filter: blur($radius);
       -o-filter: blur($radius);
      -ms-filter: blur($radius);
          filter: blur($radius);
}


@function mdi($name) {
    @return char(nth($hexes, index($names, $name)));
}


@function quarter($number) {
    @return round($number / 4);
}

@function halve($number) {
    @return round($number / 2);
}

@function double($number) {
    @return round($number * 2);
}

@function triple($number) {
    @return round($number * 3);
}

@function quadruple($number) {
    @return round($number * 4);
}
// Overrides
.card {
    box-shadow: 4px 5px 14px rgba(0, 0, 0, 0.3)
}





// Redactor JS in a lx text field
.text-field .redactor-box {
    margin-top: $base-spacing-unit;
    margin-bottom: 0;

    .redactor-toolbar, .redactor-editor {
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-bottom: none;
    }

    .redactor-editor {
        padding-bottom: 0;

        border-top: none;
    }
}



.progress-container {
    margin: auto;
}

#progress {
    .progress-circular {
        @include position(absolute, 0 null null 0);
        @include margin(0 null null 0);

        width: 100%;

        background-color: rgba(0, 0, 0, 0.4);

        z-index: 1054;

        .progress-circular__svg {
            top: 50%;
            left: 50%;

            @include margin(-50px null null -50px);
        }
    }

    .progress-linear {
        @include position(absolute, 50% null null 50%);
        @include margin(0 null null 0);

        @include transform(translateX(-50%));

        width: 80%;
    }
}

#linear-progress {
    .progress-linear {
        top: 0;
        bottom: auto;
    }
}





// Fix z-index of various elements in a dialog
.dialog-filter {
    z-index: 1054;
}

.dialog  {
    z-index: 1055;
}

.lx-select__choices, .dropdown-menu, .notification, .lx-date-filter, .lx-date-picker, .pac-container, .lightbox {
    z-index: 1056;
}





// More Dialog possibilities
// Toolbar label icon
.toolbar__icon {
    line-height: $size-l;

    margin-right: $base-spacing-unit;
}
///*------------------------------------*\
//    #SETTINGS-COMMON
//\*------------------------------------*/

// Roboto path
$roboto-font-path: "../../fonts/roboto";

// Material Design Icons path
$md-icons-font-path: "../../fonts/md-icons";

// Override defaults
$base-background-color: #fff;

$chroma-blue-1: #17B8C7;
$chroma-blue-2: #35899E;
$chroma-yellow: #F7DC32;
$chroma-orange: #FFAD3F;
$chroma-red: #FF4A4A;

$accent: #BD0201;
///*------------------------------------*\
//    #SLIDESHOW
//\*------------------------------------*/

// Slideshow
.slideshow {
    overflow: hidden;
}

    // Slideshow: wrapper
    .slideshow__wrapper {
        @include display(flex);
        @include flex-direction(row);
        @include transition-property(transform);
        @include transition-duration(0.6s);
        @include transition-timing-function($ease-out-quint);
    }

    // Slideshow: nav
    .slideshow__nav {
        @include position(absolute, 50% null null null);
        @include transform(translateY(-50%));
    }

    .slideshow__nav--prev {
        left: qadruple($base-spacing-unit);
    }

    .slideshow__nav--next {
        right: qadruple($base-spacing-unit);
    }





// Slide
.slideshow__slide.slide {
    position: relative;
    @include flex(0 0 100%);
    max-width: 100%;
}

    // Slide: image
    .slideshow__slide.slide .slide__image {
        @include position(absolute, 0 0 0 0);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    // Slide: content
    .slideshow__slide.slide .slide__content {
        @include position(absolute, 0 0 0 0);
    }
.board-photo__file-input {
    display: none;
}
.event-cover__file-input, .event-photos-videos__file-input, .event-press-clippings__file-input {
    display: none;
}

.event__cover-thumbnail {
    max-height: 300px;

    margin: auto;
    margin-top: double($base-spacing-unit);
}

.event__cover-remove {
    @include position(absolute, double($base-spacing-unit) null null null);
}

.time-date {
    .control > .full-title {
        @include font-size(18px);
        color: $white !important;
        text-transform: uppercase;
    }

    .control > .full-title, .selected, .control > .slider > .date-control > .days .day-cell:hover, .time-date > .control > .slider > .date-control > .days .day-cell:active {
        background-color: $blue !important;
    }

    .date-control {
        .title {
            .md-button {
                line-height: 3 * $base-spacing-unit;
            }
        }

        .headers, .days {
            margin-left: $base-spacing-unit + quarter($base-spacing-unit);
        }
    }

    .time-control {
        ::-webkit-inner-spin-button {
            display: none;
        }
    }

    .day-cell {
        line-height: (3 * $base-spacing-unit) + quarter($base-spacing-unit);
    }

    .buttons {
        display: none;
    }
}

.carousel {
    width: 180px;
    margin: auto;

    ul[rn-carousel] {
        height: 100px;
        width: 100%;

        li {
            display: flex !important;

            img {
                max-height: 100%;
                margin: auto;
            }
        }
    }

    .carousel__slide-indicators {
        width: 100%;

        margin-top: $base-spacing-unit;

        text-align: center;

        .rn-carousel-indicator {
            display: inline-block;

            .active {
                color: $chroma-blue-1;
            }
        }
    }

    .rn-carousel-controls {
        width: 100%;

        margin-top: (quadruple($base-spacing-unit) + halve($base-spacing-unit)) * -1;

        .rn-carousel-control {
            @include position(relative, auto auto auto auto);

            &.rn-carousel-control-prev {
                float: left;

                &:before {
                    font: normal normal normal 24px/1 MaterialDesignIcons;
                    content: mdi('arrow-left-bold-circle');
                }
            }

            &.rn-carousel-control-next {
                float: right;

                &:before {
                    font: normal normal normal 24px/1 MaterialDesignIcons;
                    content: mdi('arrow-right-bold-circle');
                }
            }
        }
    }
}

.news-cover__file-input, .news-attachments__file-input {
    display: none;
}

.news__cover-thumbnail {
    max-height: 300px;

    margin: auto;
    margin-top: double($base-spacing-unit);
}

.news__cover-remove {
    @include position(absolute, double($base-spacing-unit) null null null);
}
.partner-image__file-input {
    display: none;
}
.song-cover__file-input, .song-extract__file-input, .song-soundtracks__file-input {
    display: none;
}

.song__cover-thumbnail {
    max-height: 300px;

    margin: auto;
    margin-top: double($base-spacing-unit);
}

.song__cover-remove {
    @include position(absolute, double($base-spacing-unit) null null null);
}

.song__add-year-btn, .song__remove-extract-btn {
    position: absolute !important;
}

.song__remove-extract-btn {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.event__link {
    color: $black;
    text-decoration: none;
}

.event__cover {
    -webkit-background-size: cover !important;
            background-size: cover !important;
    min-width: 300px;
    width: 100%;
    height: 400px;
}

.event__image {
    margin: auto;
    max-height: 150px;
}
.gallery__thumbnail-wrapper {
    &:hover {
        .media__download-link {
            display: block;

            .btn {
                margin-top: 5 * $base-spacing-unit;
            }
        }
    }
}
.gallery__thumbnail-wrapper--photosVideos {
    .media__legend {
        display: none;
        width: 125px;

        margin-top: (-2 * $base-spacing-unit) + quarter($base-spacing-unit);
    }

    &:hover {
        .media__legend {
            display: block;
        }

        .media__download-link {
            display: block;

            .btn {
                margin-top: 5 * $base-spacing-unit;
            }
        }
    }
}
.gallery__thumbnail-wrapper--pressClippings {
    .media__legend {
        width: 125px;
    }
}

.media__external-link, .media__download-link {
    width: 125px;
    height: 125px;

    text-align: center;
}
.media__download-link {
    display: none;
    margin-top: -125px;
    background-color: $black-2;

    .btn {
        color: $white-1;
    }
}
.media__external-link {
    display: block;
    background-color: $black-4;

    line-height: 150px;

    .mdi {
        font-weight: bold;
        color: $black-2;
        @include font-size(38px);
    }
}

.event__gallery--thumbnail {
    -webkit-background-size: cover !important;
            background-size: cover !important;
    width: 125px;
    height: 125px;
    cursor: pointer;
}

.carousel__wrapper {
    position: relative;
    overflow: hidden;
}

.media__carousel {
    height: 600px;
    margin: 0 (2 * quadruple($base-spacing-unit)) 0 (2 * quadruple($base-spacing-unit));

    li {
        display: flex !important;
    }

    .media__carousel-thumbnail {
        max-height: 100%;
    }
}

.media__carousel-thumbnail {
    margin: auto;
}

.media__carousel-control {
    @include position(absolute, 0 null null null);

    display: block;
    height: 100%;
    width: 2 * quadruple($base-spacing-unit);

    padding: $base-spacing-unit;
    @include font-size(48px);
    font-weight: bold;

    cursor: pointer;

    .mdi, .thumbnail {
        @include position(absolute, 50% null null null);
        transform: translateY(-50%);
    }

    .thumbnail {
        @include transition(left 0.3s ease-in-out, right 0.3s ease-in-out);
    }
}

.media__carousel-control--previous .thumbnail {
    left: -100px;
}
.media__carousel-control--next .thumbnail {
    right: -100px;
}

.media__carousel-control--previous, .media__carousel-control--previous:hover .thumbnail {
    left: 0;
}
.media__carousel-control--next, .media__carousel-control--next:hover .thumbnail {
    right: 0;
}

$border-radius: 10px;

// Main menu
.main-menu {
    opacity: 0;
    @include transition(opacity 0.6s ease-in-out);

    z-index: 3;

    width: 250px;
    max-height: 90%;

    border-top-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    @include position(fixed, quadruple($base-spacing-unit) null null null);

    margin: quadruple($base-spacing-unit);
    margin-top: double($base-spacing-unit);
    @include padding($base-spacing-unit double($base-spacing-unit) $base-spacing-unit 0);

    background-color: $orange;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.6);

    &.main-menu--is-visible {
        opacity: 1;
    }

    .list-row {
        padding: 0;
    }

    .list-divider {
        margin-top: 0;
        margin-bottom: 0;
    }

    .sub-list {
        padding-bottom: $base-spacing-unit;
    }

    &:not(.ps-in-scrolling) > .ps-scrollbar-y-rail:not(:hover) .ps-scrollbar-y {
        background-color: $white !important;
    }
}

.menu-link {
    text-align: left;
    padding-top: quarter($base-spacing-unit);

    &.menu-link--is-active {
        margin-left: $base-spacing-unit * -1;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);

        .menu-link__icon {
            margin-left: 3 * $base-spacing-unit;
        }
    }

    .menu-link__icon {
        margin-right: double($base-spacing-unit);

        @include font-size(24px);

        float: left;
        margin-top: $base-spacing-unit - quarter($base-spacing-unit);
        margin-left: double($base-spacing-unit);
    }
}

.menu-link__sub-link {
    @include display(flex);
    @include flex-direction(row);
    @include align-items(center);
    height: 32px;
    padding: 0 $base-spacing-unit * 4 0 $base-spacing-unit * 2;
    cursor: pointer;
    @include font-size(14px);
    color: $white;
    line-height: 32px;
    text-decoration: none;
    white-space: nowrap;

    margin-left: 5 * $base-spacing-unit;
    width: 100%;

    &.menu-link__sub-link--is-active {
        color: $accent;
    }

    &.menu-link__sub-link--is-active, &:hover {
        font-weight: bold;
    }
}




// Logo
.menu__logo {
    max-width: 200px;
    max-height: 200px;

    margin-left: 3 * $base-spacing-unit;
}





// Floatable Account Button
.account-fab {
    opacity: 0;
    @include transition(opacity 0.6s ease-in-out);

    z-index: 4;
    @include position(absolute, 21px null null 5px);

    &.account-fab--is-visible {
        opacity: 1;
    }
}





// Toolbar
.menu__toolbar {
    text-align: center;

    margin-left: double($base-spacing-unit);
    padding-top: $base-spacing-unit;
}

.news__container {
    max-width: 100%;
    margin: auto;
}

.news__cover {
    -webkit-background-size: cover !important;
            background-size: cover !important;
    min-width: 300px;
    width: 100%;
    height: 300px;
}

.news__content--left {
    &.news__content--with-image {
        @include media-query(portable) {
            border-top: $base-spacing-unit solid $chroma-orange;
        }

        @include media-query(desk) {
            border-left: $base-spacing-unit solid $chroma-orange;
        }
    }
}

.news__info {
    a {
        color: $black-2;
    }
}

.news__card {
    .card__img--left {
        img {
            max-width: 300px;

            margin: 0;
        }
    }

    .card__img--top {
        span {
            width: 100%;

            left: 0;
            bottom: 0;

            padding: $base_spacing_unit double($base_spacing_unit);

            border-bottom: $base-spacing-unit solid $chroma-orange;
        }
    }
}

.soundtrack__loader {
    .progress-container {
        display: inline-block;
    }

    .progress-linear-wrapper {
        width: 100px;
    }

    .progress-linear {
        position: relative;
        margin-left: halve($base-spacing-unit);
    }
}

.search__voice-section {
    min-width: 200px;

    .lx-select {
        margin-top: $base-spacing-unit;
    }
}
.thumbnail {
    &.img--hovered {
        position: absolute;

        margin-top: -40px;
        margin-left: -30px;

        z-index: 1;
    }
}

.list-row__content--img-is-hovered {
    margin-left: 30px;
}

.home__president__signature {
    text-align: right;
    color: #777;
}
.contact__form {
    max-width: 800px;
    margin: auto;

    border-radius: halve($base-spacing-unit);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);

    padding: double($base-spacing-unit);
}

.song__loader {
    .progress-linear {
        max-width: 150px;
    }
}

.song--first-time {
    font-weight: bold;
}
