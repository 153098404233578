///*------------------------------------*\
//    #HEADER
//\*------------------------------------*/

// Header base styles
.header {
    display: none;

    @include position(fixed, 0 0 null 0);
    z-index: 999;
    height: 60px;
    background-color: $chroma-orange;
    @include z-depth(1);
    @include clearfix;

    &.header--is-visible {
        display: block;
    }
}





// Menubar
.menubar {
    @include media-query(desk) {
        display: none;
    }

    @include media-query(portable) {
        float: left;
    }
}

.home .menubar {
    display: none;
}





// Main Logo
.main-logo {
    float: left;

    margin: $base-spacing-unit;
    margin-left: double($base-spacing-unit);

    @include transform(rotate(12deg));

    img {
        border: 2px solid $white;
    }
}

    // Main Logo Link
    .main-logo__link {
        display: block;
        padding: halve($base-spacing-unit) $base-spacing-unit;
        border-radius: $base-round;
        @include font-size(24px);

        img {
            display: block;
            height: 100px;

            @include transition(height .5s, margin-top .5s);

            &.main-logo--small {
                margin-top: $base-spacing-unit * -1;
                height: 50px;
            }
        }
    }





// Nav bar
.nav-bar {
    height: 100%;

    margin-top: $base-spacing-unit;

    ul {
        @extend %bare-list;

        li {
            float: left;
        }
    }
}

.nav-bar--lap-and-up {
    @include media-query(palm) {
        display: none;
    }
}

.nav-bar--palm {
    @include media-query(lap-and-up) {
        display: none;
    }
}

.nav-bar__dropdown {
    border-radius: 0;
    border-top: 5px solid $chroma-blue-1;

    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
}

    // Nav Link
    .nav-bar__link {
        display: block;
        padding: 0 $base-spacing-unit;
        border-radius: double($base-round) double($base-round) 0 0;
        @include font-size(18px);
        font-weight: 600;
        color: $white;
        line-height: double($base-line-height);
        text-decoration: none;

        margin-top: halve($base-spacing-unit);

        &:hover {
            background-color: $orange-500;
        }

        @include media-query(lap) {
            @include font-size(14px);
        }
    }

.dropdown.dropdown--is-active .nav-bar__link {
    background-color: $chroma-blue-1;
}





// Main Nav
.nav-bar--main {
    @include position(absolute, null null null 160px);
}





// Login menu
.nav-bar--login {
    @include position(absolute, null double($base-spacing-unit) null null);
    margin-top: $base-spacing-unit + quarter($base-spacing-unit);
}

.nav-bar--login__dropdown {
    margin-top: $base-spacing-unit + quarter($base-spacing-unit);
}

    .nav-bar--login__btn-login {
        text-align: center;
        @include margin($base-spacing-unit $base-spacing-unit);
    }





// Legged in menu
.nav-bar--logged-in {
    @include position(absolute, null double($base-spacing-unit) null null);
    margin-top: $base-spacing-unit + halve($base-spacing-unit);
}
