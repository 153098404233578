///*------------------------------------*\
//    #FOOTER
//\*------------------------------------*/

// Header base styles
.footer {
    padding-bottom: double($base-spacing-unit);
    @include font-size(14px);
    line-height: 32px;
    text-align: center;
}

    // Footer Link
    .footer__link img {
        display: inline-block;
        vertical-align: top;
        height: 32px;
        margin-left: halve($base-spacing-unit);
    }