.gallery__thumbnail-wrapper {
    &:hover {
        .media__download-link {
            display: block;

            .btn {
                margin-top: 5 * $base-spacing-unit;
            }
        }
    }
}
.gallery__thumbnail-wrapper--photosVideos {
    .media__legend {
        display: none;
        width: 125px;

        margin-top: (-2 * $base-spacing-unit) + quarter($base-spacing-unit);
    }

    &:hover {
        .media__legend {
            display: block;
        }

        .media__download-link {
            display: block;

            .btn {
                margin-top: 5 * $base-spacing-unit;
            }
        }
    }
}
.gallery__thumbnail-wrapper--pressClippings {
    .media__legend {
        width: 125px;
    }
}

.media__external-link, .media__download-link {
    width: 125px;
    height: 125px;

    text-align: center;
}
.media__download-link {
    display: none;
    margin-top: -125px;
    background-color: $black-2;

    .btn {
        color: $white-1;
    }
}
.media__external-link {
    display: block;
    background-color: $black-4;

    line-height: 150px;

    .mdi {
        font-weight: bold;
        color: $black-2;
        @include font-size(38px);
    }
}

.event__gallery--thumbnail {
    -webkit-background-size: cover !important;
            background-size: cover !important;
    width: 125px;
    height: 125px;
    cursor: pointer;
}

.carousel__wrapper {
    position: relative;
    overflow: hidden;
}

.media__carousel {
    height: 600px;
    margin: 0 (2 * quadruple($base-spacing-unit)) 0 (2 * quadruple($base-spacing-unit));

    li {
        display: flex !important;
    }

    .media__carousel-thumbnail {
        max-height: 100%;
    }
}

.media__carousel-thumbnail {
    margin: auto;
}

.media__carousel-control {
    @include position(absolute, 0 null null null);

    display: block;
    height: 100%;
    width: 2 * quadruple($base-spacing-unit);

    padding: $base-spacing-unit;
    @include font-size(48px);
    font-weight: bold;

    cursor: pointer;

    .mdi, .thumbnail {
        @include position(absolute, 50% null null null);
        transform: translateY(-50%);
    }

    .thumbnail {
        @include transition(left 0.3s ease-in-out, right 0.3s ease-in-out);
    }
}

.media__carousel-control--previous .thumbnail {
    left: -100px;
}
.media__carousel-control--next .thumbnail {
    right: -100px;
}

.media__carousel-control--previous, .media__carousel-control--previous:hover .thumbnail {
    left: 0;
}
.media__carousel-control--next, .media__carousel-control--next:hover .thumbnail {
    right: 0;
}
