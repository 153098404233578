///*------------------------------------*\
//    #MAIN
//\*------------------------------------*/

// Main base styles
.main {
    height: 100%;
}

.main-content {
    height: 100%;
}





#main-scrollbar {
    opacity: 0;
    @include transition(opacity 0.6s ease-in-out);

    min-height: 100%;

    overflow-y: hidden;

    background-color: $white-2;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.8);

    margin: quadruple($base-spacing-unit);
    margin-right: 8 * $base-spacing-unit;
    margin-left: 270px;

    padding: quadruple($base-spacing-unit);
    padding-top: double($base-spacing-unit);

    @include position(fixed, null 0 null 0);
    z-index: 2;

    > div[ng-transclude] {
        height: 100%;
        margin-bottom: $base-spacing-unit;
    }

    &.main-scrollbar--is-visible {
        opacity: 1;
    }

    &.main-scrollbar--with-header {
        margin-top: 60px;
    }
}

// Scrollbar
.ps-container > .ps-scrollbar-y-rail {
    width: 10px !important;
    z-index: 20;

    .ps-scrollbar-y {
        width: 10px !important;

        background-color: $chroma-blue-2 !important;
    }
}

.scrollbar-y-axis {
    z-index: 999;
}





// Main section
.main-section {
    @include media-query(desk) {
        margin: 0 0 0 260px;
    }
}

    // Main section title
    .main-section__title {
        display: block;
        height: quadruple($base-spacing-unit * 2);
        margin: 0;
        padding: 0 double($base-spacing-unit);
        border-bottom: 1px solid #ddd;
        @include font-size(22px);
        font-weight: 600;
        line-height: quadruple($base-spacing-unit * 2);

        background-color: #ff3333;

        padding-left: 20px;
    }

    // Main section intro
    .main-section__intro {
        margin: 0;
        padding: double($base-spacing-unit);
        @include font-size(22px);
        font-weight: 300;

        a {
            color: $blue;
            text-decoration: none;
        }
    }

    // Main section content
    .main-section__content {
        padding: double($base-spacing-unit);

        p > code {
            padding: 2px 4px;
            font-size: 90%;
            color: $blue;
            background-color: lighten($blue, 40%);
            border-radius: 4px;
        }

        p > a {
            color: $blue;
            text-decoration: none;
        }

        p:last-child,
        ul:last-child {
            margin-bottom: 0;
        }
    }

    // Main section subtitle
    .main-section__subtitle {
        @include font-size(30px);
        font-weight: 300;
        line-height: 1;
    }





// Wrapper
.wrapper {
    .wrapper--white {
        border-radius: 5px;
        background-color: $white-1;

        padding: double($base-spacing-unit);
    }

    .wrapper--small {
        padding: quadruple($base-spacing-unit);

        width: 100%
    }
}





// Really floatable FAB, bottom right positionned
.real-fab {
    @include position(absolute, halve($base-spacing-unit) (quadruple($base-spacing-unit) + halve($base-spacing-unit)) null null);
}





// Various
.btn .progress-container {
    margin-top: halve($base-spacing-unit) * -1;
}
