// Helpers
// Make an element appears clickable
.clickable {
    cursor: pointer !important;
}

// Add a border to an image
.img--with-border {
    border: 1px solid rgba(0, 0, 0, 0.5);
}

// Display inline block.
.display-inline-block {
    display: inline-block !important;
}

.text-decoration-none {
    text-decoration: none !important;
}
