.news__container {
    max-width: 100%;
    margin: auto;
}

.news__cover {
    -webkit-background-size: cover !important;
            background-size: cover !important;
    min-width: 300px;
    width: 100%;
    height: 300px;
}

.news__content--left {
    &.news__content--with-image {
        @include media-query(portable) {
            border-top: $base-spacing-unit solid $chroma-orange;
        }

        @include media-query(desk) {
            border-left: $base-spacing-unit solid $chroma-orange;
        }
    }
}

.news__info {
    a {
        color: $black-2;
    }
}

.news__card {
    .card__img--left {
        img {
            max-width: 300px;

            margin: 0;
        }
    }

    .card__img--top {
        span {
            width: 100%;

            left: 0;
            bottom: 0;

            padding: $base_spacing_unit double($base_spacing_unit);

            border-bottom: $base-spacing-unit solid $chroma-orange;
        }
    }
}
