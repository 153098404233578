// Defaults
h1, h2, h3, h4, h5 {
    text-align: center;
    color: $accent;
}

p {
    text-indent: quadruple($base-spacing-unit);
    text-align: justify;
}

u {
    text-decoration: underline;
}

[flex-item-align="stretch"] {
    @include media-query(desk) {
        @include align-self(stretch);
    }
}

input[type="time"] {
    &::-webkit-inner-spin-button, &::-webkit-clear-button, &::-webkit-datetime-edit-millisecond-field, &::-webkit-datetime-edit-second-field {
        display: none;
    }
}

blockquote {
    margin-left: double($base-spacing-unit);
    padding-left: $base-spacing-unit;
    border-left: 1px solid $black-3
}
