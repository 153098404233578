.fade.ng-enter, .fade.ng-leave {
    @include transition(opacity 0.8s ease);
}

.fade.ng-enter {
    opacity: 0;
}

.fade.ng-enter-active {
    opacity: 1;
}

.fade.ng-leave {
    opacity: 1;

}

.fade.ng-leave-active {
    display: none;
    opacity: 0;
}
