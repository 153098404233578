.soundtrack__loader {
    .progress-container {
        display: inline-block;
    }

    .progress-linear-wrapper {
        width: 100px;
    }

    .progress-linear {
        position: relative;
        margin-left: halve($base-spacing-unit);
    }
}

.search__voice-section {
    min-width: 200px;

    .lx-select {
        margin-top: $base-spacing-unit;
    }
}