.event-cover__file-input, .event-photos-videos__file-input, .event-press-clippings__file-input {
    display: none;
}

.event__cover-thumbnail {
    max-height: 300px;

    margin: auto;
    margin-top: double($base-spacing-unit);
}

.event__cover-remove {
    @include position(absolute, double($base-spacing-unit) null null null);
}

.time-date {
    .control > .full-title {
        @include font-size(18px);
        color: $white !important;
        text-transform: uppercase;
    }

    .control > .full-title, .selected, .control > .slider > .date-control > .days .day-cell:hover, .time-date > .control > .slider > .date-control > .days .day-cell:active {
        background-color: $blue !important;
    }

    .date-control {
        .title {
            .md-button {
                line-height: 3 * $base-spacing-unit;
            }
        }

        .headers, .days {
            margin-left: $base-spacing-unit + quarter($base-spacing-unit);
        }
    }

    .time-control {
        ::-webkit-inner-spin-button {
            display: none;
        }
    }

    .day-cell {
        line-height: (3 * $base-spacing-unit) + quarter($base-spacing-unit);
    }

    .buttons {
        display: none;
    }
}

.carousel {
    width: 180px;
    margin: auto;

    ul[rn-carousel] {
        height: 100px;
        width: 100%;

        li {
            display: flex !important;

            img {
                max-height: 100%;
                margin: auto;
            }
        }
    }

    .carousel__slide-indicators {
        width: 100%;

        margin-top: $base-spacing-unit;

        text-align: center;

        .rn-carousel-indicator {
            display: inline-block;

            .active {
                color: $chroma-blue-1;
            }
        }
    }

    .rn-carousel-controls {
        width: 100%;

        margin-top: (quadruple($base-spacing-unit) + halve($base-spacing-unit)) * -1;

        .rn-carousel-control {
            @include position(relative, auto auto auto auto);

            &.rn-carousel-control-prev {
                float: left;

                &:before {
                    font: normal normal normal 24px/1 MaterialDesignIcons;
                    content: mdi('arrow-left-bold-circle');
                }
            }

            &.rn-carousel-control-next {
                float: right;

                &:before {
                    font: normal normal normal 24px/1 MaterialDesignIcons;
                    content: mdi('arrow-right-bold-circle');
                }
            }
        }
    }
}
