// Overrides
.card {
    box-shadow: 4px 5px 14px rgba(0, 0, 0, 0.3)
}





// Redactor JS in a lx text field
.text-field .redactor-box {
    margin-top: $base-spacing-unit;
    margin-bottom: 0;

    .redactor-toolbar, .redactor-editor {
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-bottom: none;
    }

    .redactor-editor {
        padding-bottom: 0;

        border-top: none;
    }
}



.progress-container {
    margin: auto;
}

#progress {
    .progress-circular {
        @include position(absolute, 0 null null 0);
        @include margin(0 null null 0);

        width: 100%;

        background-color: rgba(0, 0, 0, 0.4);

        z-index: 1054;

        .progress-circular__svg {
            top: 50%;
            left: 50%;

            @include margin(-50px null null -50px);
        }
    }

    .progress-linear {
        @include position(absolute, 50% null null 50%);
        @include margin(0 null null 0);

        @include transform(translateX(-50%));

        width: 80%;
    }
}

#linear-progress {
    .progress-linear {
        top: 0;
        bottom: auto;
    }
}





// Fix z-index of various elements in a dialog
.dialog-filter {
    z-index: 1054;
}

.dialog  {
    z-index: 1055;
}

.lx-select__choices, .dropdown-menu, .notification, .lx-date-filter, .lx-date-picker, .pac-container, .lightbox {
    z-index: 1056;
}





// More Dialog possibilities
// Toolbar label icon
.toolbar__icon {
    line-height: $size-l;

    margin-right: $base-spacing-unit;
}