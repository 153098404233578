.thumbnail {
    &.img--hovered {
        position: absolute;

        margin-top: -40px;
        margin-left: -30px;

        z-index: 1;
    }
}

.list-row__content--img-is-hovered {
    margin-left: 30px;
}
