///*------------------------------------*\
//    #SETTINGS-COMMON
//\*------------------------------------*/

// Roboto path
$roboto-font-path: "../../fonts/roboto";

// Material Design Icons path
$md-icons-font-path: "../../fonts/md-icons";

// Override defaults
$base-background-color: #fff;

$chroma-blue-1: #17B8C7;
$chroma-blue-2: #35899E;
$chroma-yellow: #F7DC32;
$chroma-orange: #FFAD3F;
$chroma-red: #FF4A4A;

$accent: #BD0201;