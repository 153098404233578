@mixin blur($radius) {
  -webkit-filter: blur($radius);
     -moz-filter: blur($radius);
       -o-filter: blur($radius);
      -ms-filter: blur($radius);
          filter: blur($radius);
}


@function mdi($name) {
    @return char(nth($hexes, index($names, $name)));
}


@function quarter($number) {
    @return round($number / 4);
}

@function halve($number) {
    @return round($number / 2);
}

@function double($number) {
    @return round($number * 2);
}

@function triple($number) {
    @return round($number * 3);
}

@function quadruple($number) {
    @return round($number * 4);
}