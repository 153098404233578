///*------------------------------------*\
//    #SLIDESHOW
//\*------------------------------------*/

// Slideshow
.slideshow {
    overflow: hidden;
}

    // Slideshow: wrapper
    .slideshow__wrapper {
        @include display(flex);
        @include flex-direction(row);
        @include transition-property(transform);
        @include transition-duration(0.6s);
        @include transition-timing-function($ease-out-quint);
    }

    // Slideshow: nav
    .slideshow__nav {
        @include position(absolute, 50% null null null);
        @include transform(translateY(-50%));
    }

    .slideshow__nav--prev {
        left: qadruple($base-spacing-unit);
    }

    .slideshow__nav--next {
        right: qadruple($base-spacing-unit);
    }





// Slide
.slideshow__slide.slide {
    position: relative;
    @include flex(0 0 100%);
    max-width: 100%;
}

    // Slide: image
    .slideshow__slide.slide .slide__image {
        @include position(absolute, 0 0 0 0);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    // Slide: content
    .slideshow__slide.slide .slide__content {
        @include position(absolute, 0 0 0 0);
    }