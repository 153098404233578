$border-radius: 10px;

// Main menu
.main-menu {
    opacity: 0;
    @include transition(opacity 0.6s ease-in-out);

    z-index: 3;

    width: 250px;
    max-height: 90%;

    border-top-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    @include position(fixed, quadruple($base-spacing-unit) null null null);

    margin: quadruple($base-spacing-unit);
    margin-top: double($base-spacing-unit);
    @include padding($base-spacing-unit double($base-spacing-unit) $base-spacing-unit 0);

    background-color: $orange;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.6);

    &.main-menu--is-visible {
        opacity: 1;
    }

    .list-row {
        padding: 0;
    }

    .list-divider {
        margin-top: 0;
        margin-bottom: 0;
    }

    .sub-list {
        padding-bottom: $base-spacing-unit;
    }

    &:not(.ps-in-scrolling) > .ps-scrollbar-y-rail:not(:hover) .ps-scrollbar-y {
        background-color: $white !important;
    }
}

.menu-link {
    text-align: left;
    padding-top: quarter($base-spacing-unit);

    &.menu-link--is-active {
        margin-left: $base-spacing-unit * -1;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);

        .menu-link__icon {
            margin-left: 3 * $base-spacing-unit;
        }
    }

    .menu-link__icon {
        margin-right: double($base-spacing-unit);

        @include font-size(24px);

        float: left;
        margin-top: $base-spacing-unit - quarter($base-spacing-unit);
        margin-left: double($base-spacing-unit);
    }
}

.menu-link__sub-link {
    @include display(flex);
    @include flex-direction(row);
    @include align-items(center);
    height: 32px;
    padding: 0 $base-spacing-unit * 4 0 $base-spacing-unit * 2;
    cursor: pointer;
    @include font-size(14px);
    color: $white;
    line-height: 32px;
    text-decoration: none;
    white-space: nowrap;

    margin-left: 5 * $base-spacing-unit;
    width: 100%;

    &.menu-link__sub-link--is-active {
        color: $accent;
    }

    &.menu-link__sub-link--is-active, &:hover {
        font-weight: bold;
    }
}




// Logo
.menu__logo {
    max-width: 200px;
    max-height: 200px;

    margin-left: 3 * $base-spacing-unit;
}





// Floatable Account Button
.account-fab {
    opacity: 0;
    @include transition(opacity 0.6s ease-in-out);

    z-index: 4;
    @include position(absolute, 21px null null 5px);

    &.account-fab--is-visible {
        opacity: 1;
    }
}





// Toolbar
.menu__toolbar {
    text-align: center;

    margin-left: double($base-spacing-unit);
    padding-top: $base-spacing-unit;
}
